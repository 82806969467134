
import { Component, Prop, Vue } from "vue-property-decorator";
import Barrel from "./Barrel.vue";
import Station from "./Station.vue";
import InventoryViewModel from "@/viewModels/InventoryViewModel";
import StationType from "@/enums/StationType";
@Component({
    components: {
        Barrel,
        Station
    },
    props: {
        inventory: InventoryViewModel,
        value: InventoryViewModel,
    }
})
export default class PlayArea extends Vue {

    public inventory!: InventoryViewModel;
    public value!: InventoryViewModel;
    public stationTypes = StationType;

    private async created() {
        console.log("created");
        console.log("inventory in playArea: ", this.inventory);
    }

    public async sendMessage(value: string) {
        this.$emit('message', value);
    }

    public addItem(itemName: string) {
        switch (itemName) {
            case "water":
                this.inventory.waterCount++;
                break;
            case "barley":
                this.inventory.barleyCount++;
                break;
            case "yeast":
                this.inventory.yeastCount++;
                break;
            case "hops":
                this.inventory.hopCount++;
                break;
            default:
                break;
        }


        // if (itemName === "hop") {
        //     console.log("getting here?");
        //     this.inventory.hopCount++;
        //     console.log("inventory: ", this.inventory);
        // }
    }

}
