
import { Component, Prop, Vue } from "vue-property-decorator";
import PlayArea from "./PlayArea.vue";
import InventoryViewModel from "@/viewModels/InventoryViewModel";
@Component({
  components: {
    PlayArea
  },
  props:{
    value: InventoryViewModel,
    inventory: InventoryViewModel,
  }
})
export default class GameWorld extends Vue {
  public myNum = 0;
  public inventory!: InventoryViewModel;
  public value!: InventoryViewModel;
  public items = [
    { title: "Home", icon: "mdi-home-city" },
    { title: "My Account", icon: "mdi-account" },
    { title: "Users", icon: "mdi-account-group-outline" },
  ];
  public messages: Array<string> = [];

  public async created(){
    console.log("gameworld created");
    console.log("inventory in gw: ", this.value);
  }

  public async appendMessage(value: string){
    console.log("game world getting message: ", value);
    this.messages.push(value);
    if(this.messages.length == 10){
      this.messages.shift();
    }
  }
}
