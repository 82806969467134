import BrewState from "@/enums/BrewState";
import BeerViewModel from "./BeerViewModel";

export default class InventoryViewModel{
    public hopCount!: number;
    public yeastCount!: number;
    public barleyCount!: number;
    public waterCount!: number;
    public beersInProgress: Array<BeerViewModel> = [];
    public completedBeers: Array<BeerViewModel> = [];
    public beerCount = 0;

    constructor(){
        this.hopCount = 0;
        this.yeastCount = 0;
        this.barleyCount = 0;
        this.waterCount = 0;
    }

    public canMakeBeer(){
        if(this.hopCount > 0 && this.yeastCount > 0 && this.barleyCount > 0 && this.waterCount > 0){
            return true;
        }else{
            return false;
        }
    }

    public makeMash(): boolean{
        if(this.waterCount > 0 && this.barleyCount > 0){
            this.waterCount--;
            this.barleyCount--;
            this.beerCount++;
            // const brew = new BeerViewModel(this.beerCount);
            // brew.name = "Newest " + this.beerCount;
            // this.beersInProgress.push(brew);
            return true;
        }else{
            //show error message
            return false;
        }
    }

    public makeWort(): boolean{
        let sufficientMaterials = false;

        if(this.hopCount > 0){
            for(const beer of this.beersInProgress) {
                if(beer.state == BrewState.MASH){
                    console.log("mash found!");
                    sufficientMaterials = true;
                    this.hopCount--;
                    beer.state = BrewState.WORT;
                    break;
                }
            }
        }else{
            console.log("insufficient hops: ", this.hopCount);
            sufficientMaterials = false;
        }

        return sufficientMaterials;
        
    }

    public ferment(): boolean{
        let sufficientMaterials = false;

        if(this.yeastCount > 0){
            for(const beer of this.beersInProgress) {
                if(beer.state == BrewState.WORT){
                    console.log("wort found!");
                    sufficientMaterials = true;
                    this.yeastCount--;
                    beer.state = BrewState.BEER;
                    break;
                }else{
                    sufficientMaterials = false;
                }
            }
        }else{
            console.log("insufficient yeast: ", this.yeastCount);
            sufficientMaterials = false;
        }

        return sufficientMaterials;
    }

    public packageBeer(): boolean{
        let sufficientMaterials = false;
        for(const beer of this.beersInProgress) {
            if(beer.state == BrewState.BEER){
                console.log("beer ready for packaging found");
                sufficientMaterials = true;
                beer.state = BrewState.PACKAGED;
                break;
            }
            else{
                console.log("no beers ready for packaging");
                sufficientMaterials = false;
            }
        }

        return sufficientMaterials;
    }

    public progressBeers(){
        let count = 0;
        this.beersInProgress.forEach((beer) => {
            if(beer.state != BrewState.PACKAGED){
                count++;
            }
        });
        return count;
    }

    public readyBeers(){
        let count = 0;
        this.beersInProgress.forEach((beer) => {
            if(beer.state == BrewState.PACKAGED){
                count++;
            }
        });
        return count;
    }

    public makeBeer(){
        if(this.canMakeBeer()){
            this.hopCount--;
            this.yeastCount--;
            this.barleyCount--;
            this.waterCount--;
            return true;
        }else{
            return false;
        }
    }

    public checkLevels(material: string){
        switch(material){
            case "water":
                return this.waterCount;
                break;
            case "barley":
                return this.barleyCount
                break;
            case "yeast":
                return this.yeastCount;
                break;
            case "hops":
                return this.hopCount;
                break;
            default:
                return -1;
                break;
        }
    }
}