
import InventoryViewModel from "@/viewModels/InventoryViewModel";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
    props:{
        material: String,
        inventoryVm: Object,
    }
})
export default class Barrel extends Vue {

    public material!: string;
    public inventoryVm!: InventoryViewModel;

    private async created() {
        console.log("barrel created");
        //TODO: uncomment this when we're ready to have things auto increment
        //window.setInterval(this.sendMessage, 5000);
    }

    private async sendMessage() {
        let invLevel = this.inventoryVm.checkLevels(this.material);
        if(invLevel < 10){
            //this.$emit('message', 'One ' + this.material + ' added to inventory');
            this.addMaterial(this.material);
        }
    }

    private async addMaterial(value: string){
        this.$emit('add', value)
    }
}
