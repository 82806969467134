
import BeerViewModel from "@/viewModels/BeerViewModel";
import { IStation } from "../IStation";
import InventoryViewModel from "@/viewModels/InventoryViewModel";
import { Component, Vue } from "vue-property-decorator";
import StationType from "@/enums/StationType";

@Component({
    props: {
        image: String,
        inventoryVm: Object,
        stationType: String,
    }
})
export default class Station extends Vue implements IStation {
    public image!: string;
    public imageString = "";
    public inventoryVm!: InventoryViewModel;
    public progress = 0;
    public beerModel!: BeerViewModel;
    public interval = 1000;
    public showProgress = false;
    public stationType!: string;
    public types = StationType;
    public inProgress = false;

    private async created() {
        console.log("station created");
        console.log("type is: ", this.stationType);
        this.imageString = "@/assets/" + this.image;
    }

    private async delay(ms) {
        // return await for better async stack trace support in case of errors.
        return await new Promise(resolve => setTimeout(resolve, ms));
    }

    public async stationAction() {
        console.log("interface level station action");
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        try {
            switch (this.stationType) {
                case this.types.MASHTON:
                    console.log("mash ton clicked");
                    if (this.inventoryVm.makeMash()) {
                        await this.displayProgress(true);
                    } else {
                        console.log("insufficient materials");
                    }
                    break;
                case this.types.KETTLE:
                    console.log("kettle clicked");
                    if (this.inventoryVm.makeWort()) {
                        await this.displayProgress();
                    } else {
                        console.log("insufficient materials");
                    }
                    break;
                case this.types.FERMENTOR:
                    console.log("fermentation vessel clicked");
                    if (this.inventoryVm.ferment()) {
                        await this.displayProgress();
                    } else {
                        console.log("insufficient materials");
                    }
                    break;
                case this.types.LINE:
                    console.log("bottling line clicked");
                    if (this.inventoryVm.packageBeer()) {
                        await this.displayProgress();
                    } else {
                        console.log("insufficient materials");
                    }
                    break;
                default:
                    break;
            }
        } finally {
            this.inProgress = false;
        }

    }
    
    private async timer(){
        let timer = window.setInterval(() => { 
            this.progress += 10 
        }, this.interval);
        await this.delay(10000);
        window.clearInterval(timer);
        this.showProgress = false;
    }

    private async displayProgress(initial?: boolean) {
        console.log("sufficient materials, begin brew");
        this.progress = 0;
        setTimeout(() => {
            this.showProgress = true;
        }, 200)
        console.log("starting progress: ", this.progress);
        await this.timer();
        console.log("ending progress: ", this.progress);
        
        if(initial){
            const brew = new BeerViewModel(this.inventoryVm.beerCount);
            brew.name = "Newest " + this.inventoryVm.beerCount;
            this.inventoryVm.beersInProgress.push(brew);
        }
        
        //this.progress = 0;
    }

    // public async beginBrew() {
    //     console.log("beginning brew");
    //     console.log("inventoryVm: ", this.inventoryVm);
    //     if (this.inventoryVm.makeMash()) {
    //         console.log("sufficient materials, begin brew");
    //         this.showProgress = true;
    //         window.setInterval(() => { this.progress += 10 }, this.interval);
    //         await this.delay(10000);
    //         this.showProgress = false;
    //         const brew = new BeerViewModel(this.inventoryVm.beerCount);
    //         brew.name = "Newest " + this.inventoryVm.beerCount;
    //         this.inventoryVm.beersInProgress.push(brew);
    //     } else {
    //         console.log("insufficient materials");

    //     }
    // }
}
