
import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "./components/HelloWorld.vue";
import GameWorld from "./components/GameWorld.vue";
import InventoryViewModel from "./viewModels/InventoryViewModel";
import BeerViewModel from "./viewModels/BeerViewModel";

@Component({
  components: {
    HelloWorld,
    GameWorld
  },
})
export default class App extends Vue {

  public inventory: InventoryViewModel = new InventoryViewModel();
  public beerCount = 0;

  public $refs!: {
    game: GameWorld
  }

  public async created() {
    console.log("App parent created");
    console.log("inventory looks like: ", this.inventory.hopCount);

  }

  public async newBrew() {
    if (this.inventory.makeBeer()) {
      this.beerCount++;
      var brew = new BeerViewModel(this.beerCount);
      brew.name = "Newest " + this.beerCount;
      this.inventory.beersInProgress.push(brew);
      let messageString = brew.name + " Started";
      this.$refs.game.appendMessage(messageString);
      console.log("beers: ", this.inventory.beersInProgress);
    }

  }
}
