import BrewState from "@/enums/BrewState";

export default class BeerViewModel{
    public beerId!: number;
    public state!: string;
    public name!: string;
    public type!: string;
    public quantity!: number;

    constructor(count:number){
        this.state = BrewState.MASH;
        this.name = "";
        this.type = "";
        this.beerId = count;
        this.quantity = 0;
    }
}